import { Form } from 'antd';
import React, { memo } from 'react';
import { INLTSwitch as Switch } from '@xbcb/form-item-components';
import { StyledEpaFormDiv, StyledInfoTooltip, StyledFlexDiv } from './styles';
import VehiclesAndEnginesForm from 'components/VehiclesAndEnginesForm';
import {
  isPesticidesPgaSupportEnabled,
  isVehiclesAndEnginesPgaSupportEnabled,
} from '../../featureFlags';
import { PgaFormBaseProps } from '../../types';
import { UsPgaFlag } from '__generated__/graphql';
import { PgaFlag } from 'libs/pgaHelpers';
import { PesticidesForm } from '../PesticidesForm';
import { useCurrentUser } from 'libs/hooks';

const PESTICIDES_FLAGS = [UsPgaFlag.Ep5, UsPgaFlag.Ep6];
const TSCA_FLAGS = [UsPgaFlag.Ep1, UsPgaFlag.Ep2, UsPgaFlag.Ep7, UsPgaFlag.Ep8];

const EpaForm: React.FC<PgaFormBaseProps> = (props) => {
  const { pgaFlag, disabled, localNamePath, fullNamePath } = props;
  const user = useCurrentUser();
  const operatorId = user?.operator?.id;
  const form = Form.useFormInstance();
  const programCode = Form.useWatch([...fullNamePath, 'programCode'], form);

  const subjectToTSCA = TSCA_FLAGS.includes(pgaFlag);
  const subjectToVehiclesAndEngines =
    isVehiclesAndEnginesPgaSupportEnabled(operatorId) &&
    PgaFlag.subjectToVehiclesAndEngines(pgaFlag);
  const subjectToPesticides =
    isPesticidesPgaSupportEnabled(operatorId) &&
    PESTICIDES_FLAGS.includes(pgaFlag);

  return (
    <StyledEpaFormDiv>
      {subjectToTSCA && (
        <Switch
          field={[...localNamePath, 'isTscaCompliant']}
          disabled={disabled}
          onWord="Compliant"
          offWord="Not Applicable"
          label="TSCA Certification"
          aria-label="TSCA Certification"
        />
      )}
      <>
        {programCode === 'ODS' && (
          <StyledFlexDiv>
            <Switch
              disabled={disabled}
              field={[...localNamePath, 'isImageSubmitted']}
              onWord="Submitted"
              offWord="Not Applicable"
              label="Non Objection Notice"
            />
            <StyledInfoTooltip title="An image of the EPA-issued Non Objection Notice is conditionally required if the ODS has been previously used (i.e., not virgin)." />
          </StyledFlexDiv>
        )}
      </>

      {subjectToVehiclesAndEngines && <VehiclesAndEnginesForm {...props} />}
      {subjectToPesticides && <PesticidesForm {...props} />}
    </StyledEpaFormDiv>
  );
};

export default memo(EpaForm);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { find, get, uniq, isObject } from 'lodash';
import accounting from 'accounting';
import {
  AccountType,
  AnyObject,
  RecordType,
  TagKey,
  WeightUnit,
} from '@xbcb/shared-types';
import {
  ChargeCode,
  ReportReconciliationStatisticsType,
} from '@xbcb/finance-types';
import {
  isNameMapKey,
  mapToAllUserTypes,
  nameMap,
  safeGet,
  unplannedCharges,
} from '@xbcb/js-utils';
import { getRecordType } from '@xbcb/core';
import { UsIorPmsStatus, ActivationStatus } from '@xbcb/party-types';
import type {
  Tag,
  Invoice,
  UsIorContinuousBondRequest,
  Weight,
  Cost,
} from '@xbcb/api-gateway-client';
import { getAMSQueryMessage } from 'libs/selectors';
import eventMap from 'libs/eventMap';
import optionalFields from 'libs/optionalFields';
import { invoiceTypeMap } from 'libs/invoice';
import moment from 'moment-business-days';
import { iorNumber } from 'libs/conceal';
import titleCaseLib from 'libs/titleCase';
import titleFieldsLib from 'libs/titleFields';
import { convertCostToString } from './convertCostToString';

export { spreadsheetFields, nameMap } from '@xbcb/js-utils';
export * from './convertCostToString';

const currencyCodeToSymbolMap: Map<string, string> = new Map([
  ['USD', '$'],
  ['EUR', '€'],
  ['GBP', '£'],
]);

export const formatCurrency = (
  number?: number,
  symbol?: string,
  decimals = 2,
) =>
  typeof number !== 'undefined'
    ? accounting.formatMoney(number, symbol, decimals)
    : number;
export const formatDollars = (number?: number, decimals = 2) =>
  formatCurrency(number, '$', decimals);

export const formatCurrencyByCurrencyCode = (
  currencyCode: string,
  amount?: number,
  decimals = 2,
) =>
  formatCurrency(amount, currencyCodeToSymbolMap.get(currencyCode), decimals);

/**
 * Formats a cost object for display.
 *
 * @param {Cost|undefined} cost - The cost object to be formatted upto two decimal places. If undefined, an empty object is returned.
 * @return {{ value: string, currency: string }} - An object with the formatted cost value and currency.
 *
 */
export const formatCost = (
  cost?: Cost,
): { value: string; currency: string } => {
  if (!cost) return { value: '', currency: '' };
  const { value, currency } = cost;
  return {
    value: value ? value.toFixed(2) : '',
    currency: currency || '',
  };
};

export const formatWeight = (
  weight?: Weight,
): { value: string; unit: string } => {
  const weightUnit = 'KILOGRAM';
  if (!weight) return { value: '', unit: '' };
  const { value, unit } = weight;
  return {
    value: value ? value.toFixed(2) : '',
    unit: WeightUnit[weightUnit] || unit,
  };
};

export const formatNumber = (number?: number) =>
  number === undefined
    ? ''
    : `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
export { invoiceTypeMap };

export const titleCase = titleCaseLib;
export const titleFields: typeof titleFieldsLib = titleFieldsLib;

export const planNames = ['Lite', 'Basic', 'Pro', 'Enterprise'];

const y = 'Yes';
const n = 'No';

export const pluralize = (string: string, count: number) =>
  count === 1 ? string : `${string}s`;

export const spreadsheetTooltips = {
  [RecordType.PRODUCT]: ['spi', 'disclaimPga', 'packingUnits'],
};

/**
 * Any updates to this configuration should also be
 * updated in the [SOP Quip](https://quip-amazon.com/NjBrAyUvW0RH/Spreadsheet-Uploader-Column-Matching#temp:C:ZID90272dbddb55464bbb29b2e11).
 */
export const spreadsheetAutoMap = {
  address: [],
  city: [],
  clientIdentifier: ['part', 'item', 'sku', 'item number'],
  countryCode: ['country'],
  description: [],
  disclaimPga: ['disclaim', 'pga', 'disclaim pga?'],
  fdaProductCode: ['fda', 'product code', 'fda product code'],
  htsNumber1: ['hts', 'htsNumber'],
  name: [],
  notes: [],
  originCountryCode: [
    'coo',
    'origin',
    'originCountry',
    'origin country',
    'country of origin',
    'origin country code',
  ],
  poNumber: ['purchase', 'order', 'po number', 'po'],
  postalCode: ['zip', 'postal'],
  quantity: [],
  rateString: ['duty', 'rate', 'duty rate'],
  spi: ['special', 'program', 'indicator'],
  supplier: [],
  stateCode: ['state'],
  totalValue: ['total'],
  uom1: ['first', 'uom', 'unit of measure', 'measure'],
  uom2: ['second', 'unit of measure2', 'measure2'],
  uom3: ['third', 'unit of measure3', 'measure3'],
  tariffUom1: ['tariff uom1'],
  tariffUom2: ['tariff uom2'],
  tariffUom3: ['tariff uom3'],
  unitValue1: ['unit', 'unitValue', 'unit value'],
  unitAssist1: ['assist', 'unitAssist', 'unit assist'],
};

const tagArray = (tags?: string[]) => {
  if (!tags || !Array.isArray(tags) || tags.length === 0) return null;
  let str = '';
  tags.forEach((t) => {
    str += t + ', ';
  });
  str = str.substr(0, str.length - 2);
  if (str.length > 15) {
    str = str.substr(0, 12) + '...';
  }
  return str;
};

const isEventMapKey = (name: string): name is keyof typeof eventMap => {
  return !!eventMap[name as keyof typeof eventMap];
};

// given shipment props
// returns status
const getStatus = (props: any) => {
  if (!props) return;
  if (props.events && props.events.length) {
    let eventCode = props.events[props.events.length - 1];
    if (
      eventCode.name &&
      !['pgaHold', 'customsHold', 'customsExam'].includes(eventCode.name) &&
      props.events.some((e: any) => e.name === 'customsRelease')
    ) {
      eventCode = { name: 'customsRelease' };
    }
    const { name } = eventCode;
    if (!name) return;
    if (isEventMapKey(name)) {
      return eventMap[name].name;
    } else {
      return name;
    }
  } else {
    return;
  }
};

const getInBondNumbers = (props: any) => {
  const inBondNumbers: string[] = [];
  if (props.inBond) {
    get(props, 'inBonds', []).forEach((inBond: any) => {
      if (inBond.number && inBond.status !== 'deleted')
        inBondNumbers.push(inBond.number);
    });
  } else {
    get(props, 'masterBills', []).forEach((mb: any) => {
      get(mb, 'houseBills', []).forEach((hb: any) => {
        if (hb.inBondNumber) inBondNumbers.push(hb.inBondNumber);
      });
    });
  }
  return uniq(inBondNumbers);
};

// given shipment props
// returns masterBill numbers found on manifest, or isf
const getMasterBills = (props: any) => {
  const masterBills =
    get(props, ['masterBills']) || get(props, ['isf', 'masterBills']);
  if (masterBills)
    return masterBills.map((masterBill: any) => masterBill.number);
};

const getHouseBills = (props: any) => {
  const masterBills =
    get(props, ['masterBills']) || get(props, ['isf', 'masterBills']) || [];

  const houseBills = masterBills.reduce((acc: any, masterBill: any) => {
    return acc.concat(get(masterBill, ['houseBills'], []));
  }, []);

  const numbers = houseBills.reduce((acc: any, bill: any) => {
    if (typeof bill === 'string') {
      acc.push(bill);
    } else if (typeof bill === 'object' && bill.number) {
      acc.push(bill.number);
    }
    return acc;
  }, []);

  if (numbers.length) return numbers;
};

// given shipment props
// returns first seller found on invoice, or isf
const getShipmentSupplierId = (props: any) =>
  get(props, ['invoices', 0, 'products', 0, 'lines', 0, 'supplierId']) ||
  get(props, ['isf', 'manufacturers', 0, 'supplierId']) ||
  get(props, ['invoices', 0, 'supplierId']) ||
  get(props, ['isf', 'sellers', 0]);

const getBillsOfLading = (props: any) => {
  const masterBills =
    get(props, ['masterBills']) || get(props, ['isf', 'masterBills']) || [];

  const houseBills = masterBills.reduce((acc: any, masterBill: any) => {
    return acc.concat(get(masterBill, ['houseBills'], []));
  }, []);

  return houseBills.concat(masterBills).reduce((acc: any, bill: any) => {
    if (typeof bill === 'string') {
      acc.push(bill);
    } else if (typeof bill === 'object' && bill.number) {
      acc.push(bill.number);
    }
    return acc;
  }, []);
};

// given shipment props
// returns first container number found
const getContainers = (props: any) => {
  const containers = new Set();
  get(props, 'containers', [])?.forEach((container: any) => {
    if (container.number) {
      containers.add(container.number);
    }
  });
  return Array.from(containers);
};

// might pass a "record" with props or without.
const getShipperName = (record: any) =>
  get(record, 'props.dba') ||
  get(record, 'dba') ||
  get(record, 'props.name') ||
  get(record, 'name');

const renderCountryCode = (
  countryCode: string,
  { countryCodes }: { countryCodes?: any },
) => get(countryCodes, [countryCode, 'name']);

const renderStateCode = (r: any, { countryCodes }: { countryCodes?: any }) =>
  get(countryCodes, [r.countryCode, 'subdivision', r.stateCode, 'name']);

const locationDataMap = {
  countryCode: (r: any, helper: any) =>
    renderCountryCode(r.countryCode, helper),
  stateCode: renderStateCode,
};

const renderPhone = (data: any, key: string) =>
  data[key]
    ? data[`${key}Country`]
      ? `+${data[`${key}Country`]} ${data[key]}${
          data[`${key}Extension`] ? ` x${data[`${key}Extension`]}` : ''
        }`
      : `${data[key]}${
          data[`${key}Extension`] ? ` x${data[`${key}Extension`]}` : ''
        }`
    : null;

const renderSupplierId = (suppliers: any, supplierId: string) =>
  get(suppliers, [supplierId, 'props', 'name']);

const renderForwarderIds = (
  { forwarderIds }: { forwarderIds?: string[] },
  { forwarders }: { forwarders: any },
) => {
  if (forwarderIds) {
    const forwarderNames = forwarderIds.reduce((acc: string[], fId) => {
      const name = get(forwarders, [fId, 'props', 'name']);
      if (name) acc.push(name);
      return acc;
    }, []);
    return forwarderNames;
  } else {
    return [];
  }
};

const renderForwarderId = (
  { shipperId }: { shipperId?: string },
  { forwarders: forwardersObject }: { forwarders: AnyObject },
) => {
  if (shipperId && forwardersObject) {
    const forwarderNames = [];
    const forwarders = Object.values(forwardersObject);
    for (let f = 0; f < forwarders.length; f++) {
      const forwarder = forwarders[f];
      if (forwarder.shipperIds && forwarder.shipperIds.includes(shipperId)) {
        forwarderNames.push(forwarder.props.name);
      }
    }
    return forwarderNames;
  } else {
    return [];
  }
};

const renderShipperId = (
  { shipperId, shipperIds }: { shipperId?: string; shipperIds?: string[] },
  {
    accountType,
    shippers,
    forwarders,
  }: { accountType?: AccountType; shippers?: any; forwarders?: any },
) => {
  if (shipperId) {
    if (shipperId.startsWith('F')) {
      if (accountType === AccountType.OPERATOR) {
        return [get(forwarders, [shipperId, 'props', 'name'], 'Unassigned')];
      } else {
        return ['Unassigned'];
      }
    }
    return [getShipperName(get(shippers, [shipperId]))];
  } else if (shipperIds) {
    return shipperIds.map((shipperId) =>
      getShipperName(get(shippers, shipperId)),
    );
  } else {
    return [];
  }
};

// We only support ACH Debit and Wire in P0 of feature parity
export const invoiceSourceMap = {
  achDebit: 'ACH Debit',
  // creditCard: 'Credit Card',
  // paypal: 'PayPal',
  // check: 'Check',
  wire: 'Wire',
};

export const getOriginCountryCode = (props: any) =>
  get(props, ['lines', 0, 'originCountryCode']);
export const getHTSNumbers = (props: any) => {
  const numbers = new Set();
  get(props, ['lines'], []).forEach((line: any) => {
    get(line, ['tariffs'], []).forEach((tariff: any) => {
      if (tariff && tariff.htsNumber) numbers.add(tariff.htsNumber);
    });
  });

  return Array.from(numbers);
};

const customerName = (
  { accountId }: { accountId?: string },
  { shippers, forwarders }: { shippers: any; forwarders: any },
) => {
  if (!accountId) return null;
  if (accountId.startsWith('F')) {
    return get(forwarders, [accountId, 'props', 'name']);
  } else if (accountId.startsWith('I')) {
    return getShipperName(get(shippers, [accountId]));
  }
  return null;
};

// used by legacy multiple shipment invoices
const getInvoiceShipmentTotal = (props: any) =>
  formatDollars(
    get(props, ['singleEntryBond'], 0) +
      get(props, ['otherAmount'], 0) +
      get(props, ['entryFee'], 0),
  );

const getInvoiceDutyAmount = (shipment: any, dutyKey: string) =>
  formatDollars(get(shipment, [dutyKey], '0.00'));

export const formatDate = (iso?: any) => {
  if (!iso) return;
  const date = moment.utc(iso);
  if (date.isValid()) return date.format('MM/DD/YY');
  return;
};

const invoiceShipment = (r: any, shipments: any) => {
  return get(shipments, [`${r.shipperId}${r.shipmentId}`, 'props']);
};

const companyDataMap = {
  ...locationDataMap,
  authorized: (r: any) => (r.authorized ? 'Authorized' : 'Sending to officer'),
  officerEmail: (r: any) => (!r.authorized ? r.officerEmail : null),
  officerName: (r: any) => (!r.authorized ? r.officerName : null),
  officerTitle: (r: any) => (!r.authorized ? r.officerTitle : null),
  secondaryOfficerEmail: (r: any) =>
    r.countryCode !== 'US' ? r.secondaryOfficerEmail : null,
  secondaryOfficerName: (r: any) =>
    r.countryCode !== 'US' ? r.secondaryOfficerName : null,
  secondaryOfficerTitle: (r: any) =>
    r.countryCode !== 'US' ? r.secondaryOfficerTitle : null,
};

export const amsQueryTypeMap = {
  entry: 'Entry number',
  bond: 'In-bond number',
  air: 'Air Waybill',
  ocean: 'Other bill of lading',
};

const getInvoiceBillToAddress = (r: any) => {
  const billingPartyAddress = get(r, 'billToParty.addresses.mailing')
    ? get(r, 'billToParty.addresses.mailing')
    : get(r, 'billToParty.addresses.physical');
  const accountingPointofContactsAddress = get(r, [
    'billToParty',
    'billingDetails',
    'accountingPointofContacts',
    0,
    'address',
  ]);
  return accountingPointofContactsAddress
    ? accountingPointofContactsAddress
    : billingPartyAddress;
};

export const getEntryWorkOrder = (record: any) => {
  // In the legacy data mapper for invoices, we inserted empty arrays into the 'lines' field
  // which is causing strange behavior here. For example, even after using `safeGet`
  // and optional chaining on the .find property, the UI will still throw an error
  // saying "safeGet.find" is undefined. Therefore, the length check is needed to prevent
  // crashing for legacy invoices.
  const lines = safeGet(record, 'lines');
  if (lines?.length) {
    return lines.find(
      async (line: any) =>
        getRecordType(line.workOrder?.id) === RecordType.US_CONSUMPTION_ENTRY,
    )?.workOrder;
  }
};

const mergeAddressToString = (address: any) => {
  // Don't show the address if there isn't one
  if (!address) return;
  const addAddressFragment = (fragment: string) =>
    fragment ? `${fragment},` : '';
  return `${addAddressFragment(get(address, 'address'))}${addAddressFragment(
    get(address, 'city'),
  )}${addAddressFragment(get(address, 'postalCode'))}
 ${addAddressFragment(get(address, 'stateCode'))}
 ${addAddressFragment(get(address, 'countryCode'))}`;
};
const getChargeAmount = (r: any, chargeKey: string) => {
  const charge = get(r, chargeKey);
  if (charge) {
    return convertCostToString(charge);
  }
  return;
};

const getIsUsIorContinuousBondRequest = (id?: string) =>
  Boolean(
    id && getRecordType(id) === RecordType.US_IOR_CONTINUOUS_BOND_REQUEST,
  );

const getInvoiceReferencesImporters = (invoice: Invoice) => {
  const importers: string[] = [];
  const entryWorkOrder = getEntryWorkOrder(invoice);
  const entryIorName = entryWorkOrder?.ior?.name;
  if (entryIorName) return entryIorName;
  const usIorContinuousBondRequestImporters = invoice?.lines?.reduce(
    (acc, { workOrder }) => {
      const { id } = workOrder || {};
      if (!id || !getIsUsIorContinuousBondRequest(id)) return acc;
      const { usIor } = workOrder as UsIorContinuousBondRequest;
      const { name: usIorName } = usIor;
      // We only want to show each WO once
      if (usIorName && !acc.includes(usIorName)) acc.push(usIorName);
      return acc;
    },
    [] as string[],
  );
  usIorContinuousBondRequestImporters?.forEach((importer) => {
    if (!importers.includes(importer)) importers.push(importer);
  });
  return importers.join(', ');
};

const getUsIorContinuousBondRequestWorkOrderFromInvoice = (invoice: Invoice) =>
  invoice?.lines?.find(({ workOrder }) =>
    getIsUsIorContinuousBondRequest(workOrder?.id),
  )?.workOrder as UsIorContinuousBondRequest | undefined;

const getActivatedDate = (invoice: Invoice) => {
  const { usIor } =
    getUsIorContinuousBondRequestWorkOrderFromInvoice(invoice) || {};
  // Return the activation time of the activation record action, fallback to
  // activationTime if there is not one found until we backfill all IORs that
  // used the previous (now deprecated) activation fields
  const activationTime =
    usIor?.activations?.find(
      ({ status }) => status === ActivationStatus.ACTIVATED,
    )?.activated?.time || usIor?.activationTime;
  return activationTime && formatDate(activationTime);
};

// each key that should be transformed must have the signature (record, helperObject) => undefined, String, or Array
const renderMap = {
  forwarderMailFrom: {},
  invoiceBillTo: {
    name: (r: any) => get(r, 'billToParty.name'),
    address: (r: any) => mergeAddressToString(getInvoiceBillToAddress(r)),
    region: (r: any) => get(getInvoiceBillToAddress(r), 'countryCode'),
  },
  invoiceDetails: {
    type: (r: any) => get(r, 'type'),
    total: (r: any) => convertCostToString(r.total),
    status: (r: any) => get(r, 'status'),
    paymentMethod: (r: any) => get(r, 'paymentMethod'),
    dueDate: (r: any) => formatDate(get(r, 'dueTime')),
  },
  // TODO make this work for all WOs, like ISF
  invoiceReferences: {
    // Always show importer at the top (it's a shared field) and combine IORs
    // if there are multiple
    importer: (r: any) => getInvoiceReferencesImporters(r),
    // Entry specific fields:
    masterBill: (r: any) =>
      get(getEntryWorkOrder(r), ['masterBills', 0, 'number']),
    vesselName: (r: any) =>
      get(getEntryWorkOrder(r), 'conveyance.conveyanceName'),
    houseBill: (r: any) =>
      get(getEntryWorkOrder(r), ['masterBills', 0, 'houseBills', 0, 'number']),
    // TODO For non-Amazon shipments, these are reference numbers
    // the importer is able to enter into the UI.
    // reference: 'Reference No.',
    shipmentNumber: (r: any) => get(r, 'workOrderGroup.shipment.id'),
    // Client booking id: for xbtms we store xbtms booking id or shipping group id
    bookingId: (r: any) =>
      get(r, 'workOrderGroup.shipment.clientReferences.bookingId'),
    entryNumber: (r: any) => get(getEntryWorkOrder(r), 'entryNumber'),
    containers: (r: any) => getContainers(getEntryWorkOrder(r)),
    // Cont bond specific fields:
    bondNumber: (r: any) =>
      getUsIorContinuousBondRequestWorkOrderFromInvoice(r)?.bondNumber,
    activationTime: (r: any) => getActivatedDate(r),
    // Always show poNumbers at the bottom (although it's a shared field)
    poNumbers: (r: any) =>
      (r?.workOrderGroup?.tags as Tag[] | undefined)
        ?.filter(({ key }) => key === TagKey.PO_NUMBER)
        ?.map(({ value }) => value),
  },
  reportReconciliationRequestSummary: {
    fileName: (r: any) => get(r, 'document.fileName'),
    fileType: (r: any) => get(r, 'document.extension'),
    reportType: (r: any) => r.type,
    status: (r: any) => r.status,
    uploader: (r: any) => r.uploader,
    createTime: (r: any) => r.createdTime,
    totalRows: (r: any) =>
      get(
        find(r.statistics, {
          type: ReportReconciliationStatisticsType.TOTAL_ROWS,
        }),
        'value',
      ),

    cbmsRows: (r: any) =>
      get(
        find(r.statistics, {
          type: ReportReconciliationStatisticsType.NUMBER_OF_CBMS_ENTRIES,
        }),
        'value',
      ),
  },
  bulkUploadRequestSummary: {
    uploader: (r: any) => r.uploader,
    status: (r: any) => r.status,
    createTime: (r: any) => r.createdTime,
    totalRows: (r: any) => r.totalRows,
  },
  invoiceAMSQuery: {
    createdTime: (r: any) => formatDate(r.createdTime),
    amount: (r: any) => formatDollars(r.amount),
  },
  invoiceSubscription: {
    plan: (r: any) => titleCase(r.plan),
    period: (r: any) => titleCase(r.period),
  },
  invoiceBond: {
    shipper: (r: any, { shippers }: { shippers: any }) =>
      getShipperName(shippers[r.shipperId]),
    activatedTime: (r: any) => formatDate(r.activatedTime),
    coverage: (r: any) => formatDollars(r.coverage),
    amount: (r: any) => formatDollars(r.amount),
  },
  invoiceClassification: {
    name: (r: any, { classifications }: any) =>
      get(classifications, [r.classificationId, 'props', 'description'], ''),
    requestDate: (r: any, { classifications }: any) =>
      formatDate(get(classifications, [r.classificationId, 'createdTime'])),
    htsNumber: (r: any, { classifications }: any) =>
      getHTSNumbers(classifications),
    amount: (r: any) => formatDollars(r.amount),
    shipper: (r: any, { shippers }: any) =>
      getShipperName(shippers[r.shipperId]),
  },
  invoiceShipment: {
    supplierId: (r: any, { suppliers, shipments }: any) =>
      suppliers &&
      renderSupplierId(
        suppliers,
        getShipmentSupplierId(invoiceShipment(r, shipments)),
      ),
    poNumber: (r: any, { shipments }: any) =>
      get(invoiceShipment(r, shipments), 'poNumbers'),
    billOfLading: (r: any, { shipments }: any) =>
      getBillsOfLading(invoiceShipment(r, shipments)),
    container: (r: any, { shipments }: any) =>
      getContainers(invoiceShipment(r, shipments)),
    dutyAmount: (r: any, { shipments, invoiceType }: any) => {
      if (invoiceType === 'statement') {
        return getInvoiceDutyAmount(
          invoiceShipment(r, shipments),
          'dutiesFees',
        );
      } else {
        return getInvoiceDutyAmount(r, 'dutyAmount');
      }
    },
    adDuty: (r: any) => formatDollars(get(r, 'adDuty', '0.00')),
    cvDuty: (r: any) => formatDollars(get(r, 'cvDuty', '0.00')),
    singleEntryBond: (r: any) =>
      formatDollars(get(r, 'singleEntryBond', '0.00')),
    entryFee: (r: any) => formatDollars(get(r, 'entryFee', '0.00')),
    canadianEntryFee: (r: any) =>
      formatDollars(get(r, 'canadianEntryFee', '0.00')),
    consolidatedEntryFee: (r: any) =>
      formatDollars(get(r, 'consolidatedEntryFee', '0.00')),
    manualCreationFee: (r: any) =>
      formatDollars(get(r, 'manualCreationFee', '0.00')),
    hotFee: (r: any) => formatDollars(get(r, 'hotFee', '0.00')),
    cancelationFee: (r: any) => formatDollars(get(r, 'cancelationFee', '0.00')),
    weekendFee: (r: any) => formatDollars(get(r, 'weekendFee', '0.00')),
    inBondFee: (r: any) => formatDollars(get(r, 'inBondFee', '0.00')),
    amendmentFee: (r: any) => formatDollars(get(r, 'amendmentFee', '0.00')),
    otherAmount: (r: any) => formatDollars(get(r, 'otherAmount', '0.00')),
    total: getInvoiceShipmentTotal,
    shipper: (r: any, { shippers }: any) =>
      getShipperName(shippers[r.shipperId]),
    shipmentNumber: (r: any, { accountType, shipments }: any) => {
      const fullShipmentId = r.shipperId + r.shipmentId;
      const clientNumber = get(shipments, [fullShipmentId, 'clientNumber']);
      if (!clientNumber) return [fullShipmentId];
      return accountType === AccountType.OPERATOR
        ? [fullShipmentId, clientNumber]
        : [clientNumber, fullShipmentId];
    },
  },
  summaryCharges: {
    dutiesFees: (r: any) => {
      const value =
        get(r, 'duties', 0) + get(r, 'taxes', 0) + get(r, 'fees', 0);
      return convertCostToString({
        currency: r.currencyCode,
        value,
      });
    },
    [ChargeCode.DUTY]: (r: any) => getChargeAmount(r, ChargeCode.DUTY),
    [ChargeCode.DUTY_PMS]: (r: any) => getChargeAmount(r, ChargeCode.DUTY_PMS),
    [ChargeCode.DUTY_DAILY]: (r: any) =>
      getChargeAmount(r, ChargeCode.DUTY_DAILY),
    [ChargeCode.AD_DUTY]: (r: any) => getChargeAmount(r, ChargeCode.AD_DUTY),
    [ChargeCode.CV_DUTY]: (r: any) => getChargeAmount(r, ChargeCode.CV_DUTY),
    [ChargeCode.SINGLE_ENTRY_BOND]: (r: any) =>
      getChargeAmount(r, ChargeCode.SINGLE_ENTRY_BOND),
    [ChargeCode.ENTRY_FEE]: (r: any) =>
      getChargeAmount(r, ChargeCode.ENTRY_FEE),
    [ChargeCode.CANADIAN_ENTRY_FEE]: (r: any) =>
      getChargeAmount(r, ChargeCode.CANADIAN_ENTRY_FEE),
    [ChargeCode.CONSOLIDATED_ENTRY_FEE]: (r: any) =>
      getChargeAmount(r, ChargeCode.CONSOLIDATED_ENTRY_FEE),
    [ChargeCode.MANUAL_CREATION_FEE]: (r: any) =>
      getChargeAmount(r, ChargeCode.MANUAL_CREATION_FEE),
    // TODO this doesn't exist anymore, add other hot fees
    // [ChargeCode.HOT_FEE]: (r: any) => getChargeAmount(r, ChargeCode.HOT_FEE),
    [ChargeCode.AMENDMENT_FEE]: (r: any) =>
      getChargeAmount(r, ChargeCode.AMENDMENT_FEE),
    [ChargeCode.CANCELATION_FEE]: (r: any) =>
      getChargeAmount(r, ChargeCode.CANCELATION_FEE),
    [ChargeCode.ISF_BOND]: (r: any) => getChargeAmount(r, ChargeCode.ISF_BOND),
    [ChargeCode.ISF_FEE]: (r: any) => getChargeAmount(r, ChargeCode.ISF_FEE),
    [ChargeCode.IN_BOND_FEE]: (r: any) =>
      getChargeAmount(r, ChargeCode.IN_BOND_FEE),
    [ChargeCode.IMPORT_VALUE_ADDED_TAX]: (r: any) =>
      getChargeAmount(r, ChargeCode.IMPORT_VALUE_ADDED_TAX),
    adDuty: (r: any) => formatDollars(get(r, 'adDuty')),
    cvDuty: (r: any) => formatDollars(get(r, 'cvDuty')),
    stbAmount: (r: any) => getChargeAmount(r, 'stbAmount'),
    ...Object.keys(unplannedCharges).reduce((acc: any, chargeKey) => {
      acc[chargeKey] = (r: any) => getChargeAmount(r, chargeKey);
      return acc;
    }, {}),
    disbursementFee: (r: any) => getChargeAmount(r, 'disbursementFee'),
    total: (r: any) => getChargeAmount(r, 'total'),
  },
  summaryStatistics: {
    entryNumber: (r: any) => get(r, 'entryNumber'),
    entryType: (r: any, { entryTypeCodes }: any) =>
      r.entryType &&
      get(entryTypeCodes, r.entryType) &&
      `${get(entryTypeCodes, r.entryType)} (${r.entryType})`,
    collectionStatus: (r: any, { collectionStatusCodes }: any) =>
      get(collectionStatusCodes, r.collectionStatus),
    paymentType: (r: any) => get(r, 'paymentType'),
    pms: (r: any) =>
      get(r, 'periodicMonthlyStatement') &&
      ['6', '7'].includes(get(r, 'paymentType'))
        ? y
        : n,
    pmsMonth: (r: any) => r.month && moment(r.month).format('MMMM YYYY'),
    payingDirect: (r: any) =>
      typeof r.paymentType !== 'undefined'
        ? ['7', '3', '1'].includes(r.paymentType)
          ? y
          : n
        : get(r, 'payerUnitNumber')
        ? y
        : n,
    totalValue: (r: any) => formatDollars(r.totalValue),
    stbAmount: (r: any) =>
      r.stbAmount ? formatDollars(r.stbAmount) : undefined,
    statementStatus: (r: any) =>
      r.paymentType === '1'
        ? 'Not on a statement'
        : r.statementDate
        ? 'Final'
        : r.printDate
        ? 'Preliminary'
        : r.entryFiled
        ? 'Pending'
        : undefined,
    cargoControlNumber: (r: any) => get(r, 'cargoControlNumber'),
    numberOfLines: (r: any) => get(r, 'b3.lines'),
    exchangeRate: (r: any) => get(r, 'b3.exchangeRate'),
    liquidationReasonCodes: (r: any, { liquidationReasons }: any) =>
      get(r, 'liquidationReasonCodes', [])
        .map((code: string) => liquidationReasons[code])
        .join(', '),
    extensionSuspensionReasonCodes: (
      r: any,
      { extensionSuspensionReasons }: any,
    ) =>
      get(r, 'extensionSuspensionReasonCodes', [])
        .map((code: string) => extensionSuspensionReasons[code])
        .join(', '),
  },
  summaryTimeline: {
    exportDate: (r: any) => formatDate(r.exportDate),
    importDate: (r: any) => formatDate(r.importDate),
    entryDate: (r: any) =>
      formatDate(r.isIT ? r.estimatedEntryDate : r.importDate),
    releaseDate: (r: any) => formatDate(r.releaseDate),
    prelimStatementDate: (r: any) => formatDate(r.printDate),
    statementDate: (r: any) => formatDate(r.finalGeneratedTime),
    // According to https://app.asana.com/0/1161330647956966/1201448362269094
    // pmsDate should be 15th business day of that month
    pmsDate: (r: any) => {
      // PSC does not have periodicMonthlyStatement.month field
      if (!r?.month) return;
      return formatDate(moment(r.month).utc().startOf('month').businessAdd(15));
    },
  },
  summaryCustoms: {
    duties: (r: any) => formatDollars(r.duties),
    taxes: (r: any) => (r.taxes ? formatDollars(r.taxes) : undefined),
    fees: (r: any) => formatDollars(r.fees),
    adDuties: (r: any) =>
      r.adDuties ? formatDollars(get(r, 'adDuties')) : undefined,
    cvDuties: (r: any) =>
      r.cvDuties ? formatDollars(get(r, 'cvDuties')) : undefined,
    total: (r: any) =>
      formatDollars(
        get(r, 'duties', 0) +
          get(r, 'fees', 0) +
          get(r, 'adDuties', 0) +
          get(r, 'cvDuties', 0),
      ),
    b3Duty: (r: any) => formatDollars(get(r, 'b3.duty')),
    b3SalesTax: (r: any) => formatDollars(get(r, 'b3.salesTax')),
    b3ExciseTax: (r: any) => formatDollars(get(r, 'b3.exciseTax')),
  },
  [RecordType.SHIPMENT]: {
    shipperId: renderShipperId,
    forwarderIds: renderForwarderIds,
    status: getStatus,
    masterBill: getMasterBills,
    bill: getBillsOfLading,
    container: getContainers,
    portOfLadingCode: (r: any, { portOfLadingCodes }: any) =>
      get(portOfLadingCodes, [r.portOfLadingCode, 0]),
    portOfUnladingCode: (r: any, { portOfUnladingCodes }: any) =>
      r.isIT
        ? get(portOfUnladingCodes, [r.portOfEntryCode, 'name'])
        : get(portOfUnladingCodes, [r.portOfUnladingCode, 'name']),
    etd: (r: any) => formatDate(r.exportDate),
    eta: (r: any) => formatDate(r.importDate),
    lfd: (r: any) => formatDate(r.lfd),
    poNumber: (r: any) => r.poNumbers,
    supplierId: (r: any, { suppliers }: any) =>
      suppliers && renderSupplierId(suppliers, getShipmentSupplierId(r)),
    shipmentNumber: (r: any, { accountType }: any) => {
      if (!r.clientNumber) return [r.shipmentId];
      return accountType === AccountType.OPERATOR
        ? [r.shipmentId, r.clientNumber]
        : [r.clientNumber, r.shipmentId];
    },
    isfSubmittedTime: (r: any) =>
      get(find(r.events, { name: 'isfSubmitted' }), 'time'),
    isfFiledTime: (r: any) => get(find(r.events, { name: 'isfFiled' }), 'time'),
    isfSubmitter: (r: any) =>
      get(find(r.events, { name: 'isfSubmitted' }), 'userName'),
  },
  shipmentReferences: {
    shipperId: renderShipperId,
    createdBy: (r: any) => r.createdByName,
    poNumber: (r: any) => r.poNumbers,
    entryNumber: (r: any) =>
      get(r, 'entryNumber') || get(r, 'props.entryNumber'),
    container: getContainers,
    houseBill: getHouseBills,
    masterBill: getMasterBills,
    inBondNumber: getInBondNumbers,
  },
  [RecordType.FORWARDER]: {
    shipperId: renderShipperId,
    tags: (r: any) => tagArray(r.tags),
    dispatchPhone: (r: any) => renderPhone(r, 'dispatchPhone'),
    phone: (r: any) => renderPhone(r, 'phone'),
    ...locationDataMap,
  },
  [RecordType.US_CONSIGNEE]: {
    shipperId: renderShipperId,
    phone: (r: any) => renderPhone(r, 'phone'),
    ...locationDataMap,
    ...companyDataMap,
    name: getShipperName,
    officerPhone: (r: any) => renderPhone(r, 'officerPhone'),
    iorNumber: (r: any, { accountType }: { accountType: AccountType }) =>
      iorNumber({ iorNumber: r.iorNumber, accountType }),
    // iorNumberType: (r: any) =>
    //  get(r, 'props.iorNumberType')
    //    ? get(r, 'props.iorNumberType').toUpperCase()
    //    : undefined,
  },
  [RecordType.TRUCKER]: {
    shipperId: renderShipperId,
    tags: (r: any) => tagArray(r.tags),
    dispatchPhone: (r: any) => renderPhone(r, 'dispatchPhone'),
    phone: (r: any) => renderPhone(r, 'phone'),
    ...locationDataMap,
  },
  [RecordType.FACILITY]: {
    shipperId: renderShipperId,
    tags: (r: any) => tagArray(r.tags),
    receivingPhone: (r: any) => renderPhone(r, 'receivingPhone'),
    phone: (r: any) => renderPhone(r, 'phone'),
    ...locationDataMap,
  },
  [RecordType.SUPPLIER]: {
    shipperId: renderShipperId,
    ...locationDataMap,
  },
  [RecordType.CLASSIFICATION]: {
    shipperId: renderShipperId,
    originCountryCode: (r: any, helper: any) =>
      renderCountryCode(r.originCountryCode || getOriginCountryCode(r), helper),
    htsNumber: getHTSNumbers,
    classified: (r: any) => (r.classified ? y : n),
    updatedTime: (r: any) => formatDate(r.updatedTime),
    createdTime: (r: any) => formatDate(r.createdTime),
  },
  [RecordType.PRODUCT]: {
    shipperId: renderShipperId,
    supplierId: (r: any, { suppliers }: any) =>
      renderSupplierId(suppliers, get(r, ['lines', 0, 'supplierId'])),
    unitValue: (r: any) =>
      formatDollars(get(r, ['lines', 0, 'tariffs', 0, 'unitValue'])),
    description: (r: any) => get(r, ['lines', 0, 'description']),
    htsNumber: getHTSNumbers,
    originCountryCode: (r: any, helper: any) =>
      renderCountryCode(getOriginCountryCode(r), helper),
  },
  [RecordType.INVOICE]: {
    accountId: customerName,
    shipperId: renderShipperId,
    source: (r: any) =>
      invoiceSourceMap[r.source as keyof typeof invoiceSourceMap],
    type: (r: any) => invoiceTypeMap[r.type as keyof typeof invoiceTypeMap],
    amount: (r: any) => formatDollars(r.amount),
    invoicedTime: (r: any) => formatDate(r.invoicedTime),
    dueTime: (r: any) => formatDate(r.dueTime),
    invoiceNumber: (r: any) => r.qboNumber,
    status: (r: any) =>
      r.status === 'upcoming'
        ? 'Not Yet Posted'
        : r.status === 'pendingVoid'
        ? 'Void'
        : titleCase(r.status),
  },
  [RecordType.SHIPPER]: {
    ...companyDataMap,
    name: getShipperName,
    programCodes: (r: any) => get(r, 'programCodes', []).join(', '),
    vertical: (r: any, { verticalCodes }: any) =>
      verticalCodes?.[r.vertical as keyof typeof verticalCodes],
    forwarderId: renderForwarderId,
    activated: (r: any) => (r.activated ? y : n),
    status: (r: any) => r.status,
    pmsRequestTime: (r: any) => formatDate(r.pmsRequestTime),
    phone: (r: any) => renderPhone(r, 'phone'),
    officerPhone: (r: any) => renderPhone(r, 'officerPhone'),
    confidentialityRequestTime: (r: any) =>
      !r.confidentialityConfirmedTime
        ? formatDate(r.confidentialityRequestTime)
        : undefined,
    confidentialityConfirmedTime: (r: any) =>
      r.confidentialityConfirmedTime
        ? formatDate(moment(r.confidentialityConfirmedTime).add(2, 'y'))
        : undefined,
    pms: (r: any) => (r.pms === UsIorPmsStatus.ACTIVE && !r.noPMS ? y : n),
    payingDirect: (r: any) => (r.payerUnitNumber ? y : n),
    bond: (r: any) => (r.bond ? y : n),
    bondRenewalTime: (r: any) => formatDate(r.bondRenewalTime),
    bondEffectiveDate: (r: any) => formatDate(r.bondEffectiveDate),
    bondExpires: (r: any) => formatDate(r.bondRenewalTime),
    businessUnit: (r: any) => r.businessUnit,
    voided: (r: any) => (r.voided ? y : n),
    bondAmount: (r: any) => formatDollars(r.bondAmount, 0),
    bondTerminationDate: (r: any) => formatDate(r.bondTerminationDate),
    iorNumber: (r: any, { accountType }: any) =>
      iorNumber({ iorNumber: r.iorNumber, accountType }),
    officerName: (r: any) => r.officerName,
    officerTitle: (r: any) => r.officerTitle,
  },
  ...mapToAllUserTypes({
    loggedIn: (r: any) =>
      r.userId && r.userId.startsWith('us-west-2') ? y : n,
    createdTime: (r: any) => formatDate(r.createdTime),
  }),
  profile: {
    officePhone: (r: any) => renderPhone(r, 'officePhone'),
    mobilePhone: (r: any) => renderPhone(r, 'mobilePhone'),
  },
  onboardingProfileDetails: {
    officePhone: (r: any) => {
      const { country = '', number = '' } = r.officePhone || {};
      return number ? country + number : null;
    },
    mobilePhone: (r: any) => {
      const { country = '', number = '' } = r.mobilePhone || {};
      return number ? country + number : null;
    },
  },
  onboardingCompanyDetails: {
    name: (r: any) => r.company?.name,
    email: (r: any) => r.company?.accountingEmail,
    phone: (r: any) => {
      const { country, number } = r.phone || {};
      return number ? country + number : null;
    },
    countryCode: (r: any) => r.addresses?.mailing?.countryCode,
    stateCode: (r: any) => r.addresses?.mailing?.stateCode,
    postalCode: (r: any) => r.addresses?.mailing?.postalCode,
    city: (r: any) => r.addresses?.mailing?.city,
    address1: (r: any) => r.addresses?.mailing?.address,
  },
  subscription: {
    period: (r: any) => titleCase(r.period),
    plan: (r: any) =>
      typeof r.plan !== 'undefined' ? `${planNames[r.plan]}` : null,
    paymentMethod: (r: any) =>
      invoiceSourceMap[r.paymentMethod as keyof typeof invoiceSourceMap],
  },
  onboardingBillingDetails: {
    period: (r: any) => titleCase(r.period),
    plan: (r: any) => titleCase(r.plan),
    paymentMethod: (r: any) =>
      invoiceSourceMap[r.paymentMethod as keyof typeof invoiceSourceMap],
  },
  company: companyDataMap,
  duties: {
    paying: (r: any) => (r.paying === 'direct' ? 'Direct' : 'INLT bill me'),
    payerUnitNumber: (r: any) =>
      r.paying === 'direct'
        ? r.needPayerUnitNumber
          ? 'Requesting from CBP'
          : r.payerUnitNumber
        : null,
    paymentMethod: (r: any) =>
      r.paying === 'indirect'
        ? invoiceSourceMap[r.paymentMetho as keyof typeof invoiceSourceMap]
        : null,
  },
  bond: {
    need: (r: any) => (r.need ? y : n),
    hasReason: (r: any) =>
      !r.need
        ? r.hasReason === 'continuous'
          ? 'I have a Continuous Bond'
          : 'I will purchase Single Transaction Bonds'
        : null,
    duties: (r: any) => (r.need ? formatDollars(r.duties) : null),
    coverage: (r: any) => (r.need ? formatDollars(r.coverage) : null),
    quote: (r: any) => (r.need ? formatDollars(r.quote) : null),
    complex: (r: any) =>
      r.need ? (r.complex ? r.complex.join(', ') : 'None') : null,
  },
  amsQuery: {
    queryType: (r: any) =>
      amsQueryTypeMap[r.queryTyp as keyof typeof amsQueryTypeMap],
    createdTime: (r: any) => moment(r.createdTime).format('MM/DD HH:mm'),
    response: (r: any) => getAMSQueryMessage(r),
  },
  [RecordType.CUSTOM_DOMAIN]: {
    certificate: (r: any) => {
      get(r.certificate, 'validationStatus');
    },
  },
};

// TODO a lot of the helpers we use are just data that we can get here using libs/codes.
export const dataMap = (store: any, helper: any) =>
  Object.keys(renderMap).reduce((res: any, key) => {
    if (store[key] && isNameMapKey(key)) {
      res[key] = Object.keys(
        nameMap[key as keyof typeof nameMap] as Record<string, unknown>,
      ).reduce((acc: any, cur: any) => {
        const valueFromStore = store[key][cur];
        const defaultValue = isObject(valueFromStore)
          ? get(valueFromStore, 'amount')
          : valueFromStore;
        const renderMapFunc = get(renderMap, [key, cur]);
        return {
          ...acc,
          [cur]: {
            name: get(nameMap, [key, cur]),
            data: renderMapFunc
              ? renderMapFunc(store[key] || {}, helper)
              : defaultValue,
            optional: get(optionalFields, [key, cur]),
          },
        };
      }, {});
    }
    return res;
  }, {});

/* eslint-enable max-len */

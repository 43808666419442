import { getEnv } from '@xbcb/ui-utils';
import { UiStage, uiStageToBackendStage } from '@xbcb/ui-types';
import {
  RENEWAL_DATE_FEATURE,
  renewalDateFeature,
  cbpReg2ChangesFeature,
  CBP_REG_2_CHANGES_FEATURE,
} from '@xbcb/feature-flags';

const { stage } = getEnv();

export const iscbpReg2ChangesFeatureEnabled =
  CBP_REG_2_CHANGES_FEATURE.isEnabled(cbpReg2ChangesFeature, {
    stage: uiStageToBackendStage[stage],
  });

export const isVehiclesAndEnginesPgaSupportEnabled = (operatorId?: string) =>
  stage !== UiStage.PROD ||
  [
    'operatorUser_a196f24d-06c1-49b2-8ba3-c8a166c85a40', // travsmar@
    'operatorUser_ecfb29b0-b4c2-4c4a-989c-78d45f20e5d4', // parkeima@
  ].some((id) => id === operatorId);

export const isPesticidesPgaSupportEnabled = (operatorId?: string) =>
  stage !== UiStage.PROD ||
  [
    'operatorUser_a196f24d-06c1-49b2-8ba3-c8a166c85a40', // travsmar@
    'operatorUser_ecfb29b0-b4c2-4c4a-989c-78d45f20e5d4', // parkeima@
  ].some((id) => id === operatorId);

export const isRenewalDateEnabled: boolean = RENEWAL_DATE_FEATURE.isEnabled(
  renewalDateFeature,
  {
    stage: uiStageToBackendStage[stage],
  },
);

export const isMultiBrokerLoginEnabled = stage !== UiStage.PROD;

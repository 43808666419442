import { Spin, SpinProps } from 'antd';
import React from 'react';
import { StyledSpinContainer } from './styles';

interface LoadingProps {
  size?: SpinProps['size'];
}

export const Loading: React.FC<LoadingProps> = ({ size }) => {
  return (
    <StyledSpinContainer>
      <Spin data-testid="loading-spinner" size={size} />
    </StyledSpinContainer>
  );
};

export default Loading;

import { getEnv } from '@xbcb/ui-utils';
import { UiStage } from '@xbcb/ui-types';
import { client as apolloClient } from '@xbcb/apollo-client';
import { LinkBasedS3Uploader, LinkGenerationFunction } from '@xbcb/record-dao';
import { usConsumptionEntryS3SaverDecompressionFunction } from '@xbcb/schema-transformer/dist/usConsumptionEntryLargePayloadUtils/usConsumptionEntryS3Saver';
import { UsceLargeEntryClientBase } from '@xbcb/schema-transformer/dist/usConsumptionEntryLargePayloadUtils/usConsumptionEntryClientBase';
import { createWorkOrderPresignedUrlGqlString } from './createWorkOrderPresignedUrl.query';

const { stage } = getEnv();

// Exported here so that we don't have to import from @xbcb/work-order-utils elsewhere on the frontend
/** The function used to decompress any fields uploaded by a UiUsConsumptionEntryS3Uploader */
export const uiUsConsumptionEntryS3SaverDecompressionFunction =
  usConsumptionEntryS3SaverDecompressionFunction;

const s3LinkGenerationFunction = (async () => {
  const response = await apolloClient.mutate({
    mutation: createWorkOrderPresignedUrlGqlString,
  });
  return response.data.createWorkOrderPresignedUrl;
}) as LinkGenerationFunction; // we know that this mutation will have the correct output, so cast it to fix the compiler error

const uploadFunction = async (
  uploadLink: string,
  content: BodyInit | null | undefined,
) => {
  return fetch(uploadLink, {
    method: 'PUT',
    body: content,
    headers: {
      'Content-type': 'application/octet-stream',
    },
  });
};

const frontendS3Uploader = new LinkBasedS3Uploader(
  s3LinkGenerationFunction,
  uploadFunction,
);

/** A UsceLargeEntryClient that uses the apolloClient to create uploadLinks and fetch to upload to them */
export class UiUsConsumptionEntryLargeEntryClient extends UsceLargeEntryClientBase {
  constructor() {
    super(frontendS3Uploader, stage !== UiStage.PROD);
  }
}

let frontendUsEntryLargeEntryClient: UiUsConsumptionEntryLargeEntryClient;
/**
 * A utility to return the cached UiUsConsumptionEntryLargeEntryClient, or generate a new one
 * @return {UiUsConsumptionEntryLargeEntryClient} the cached client, or a new one if none exists yet.
 */
export const getFrontendUsEntryLargeEntryClient =
  (): UiUsConsumptionEntryLargeEntryClient => {
    if (frontendUsEntryLargeEntryClient === undefined) {
      frontendUsEntryLargeEntryClient =
        new UiUsConsumptionEntryLargeEntryClient();
    }
    return frontendUsEntryLargeEntryClient;
  };

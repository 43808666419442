import React from 'react';
import moment from 'moment';
import { memoFragments } from '@xbcb/client-queries';
import { AccountType, AnyObject, RecordType } from '@xbcb/shared-types';
import {
  workOrderTaskFragments,
  workOrderTaskStepFragments,
} from '@xbcb/work-order-queries';
import type { UsIorContinuousBondRequest as ApigUsIorContinuousBondRequest } from '@xbcb/api-gateway-client';
import {
  WorkOrderMilestoneName,
  WorkOrderStatus,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import { setModal } from 'actions';
import { ModalKey } from 'types';
import { executeMutation } from 'libs/executeMutation';
import { transformUsIorContinuousBondRequest } from 'libs/formTransforms';
import {
  cancelUsIorContinuousBondRequestRenewal,
  createWorkOrderMilestoneMutation,
  usIorContinuousBondRequestFragments,
} from 'libs/sharedQueries';
import { UsIorContinuousBondRequest } from '../../loadableComponents';
import { AppRecordOnConfirmPayload } from '../../types';
import {
  appRecordAbiDataMenuItem,
  appRecordAssignWorkOrderTaskMenuItem,
  appRecordMemoMenuItem,
} from '../utils';
import { WorkOrderRoute } from '.';
import BondInfoCard from 'components/BondInfoCard';
import UsIorContinuousBondRequestSecondaryHeader from 'components/UsIorContinuousBondRequestSecondaryHeader';
import { isRecordCanceled } from 'libs/workOrderConditions';
import { v4 } from 'uuid';
import { completeWorkOrderTaskButton } from '../utils/completeWorkOrderTaskButton';
import { usIorContinuousBondRequestChargeBondKebabMenuItem } from '../utils/usIorContinuousBondRequestChargeBondKebabMenuItem';
import { usIorContinuousBondRequestChargeBondRenewalKebabMenuItem } from '../utils/usIorContinuousBondRequestChargeBondRenewalKebabMenuItem';
import { appRecordSendAbiMessage } from '../utils/appRecordSendAbiMessage';
import { message } from 'antd';
import { isRenewalDateEnabled } from '../../../featureFlags';

// Returns the renewalDate for the present Roanoke continuous bond (if there is one)
const getPresentRoanokeContinuousBondRenewalDateFromUsIor = (
  usIor?: AnyObject,
) => {
  const presentBond = usIor?.continuousBonds?.present;
  if (!presentBond) return;
  const { suretyCode, typeCode, renewalDate } = presentBond;
  if (suretyCode !== '036' || typeCode !== 'A' || !renewalDate) return;
  return renewalDate as string;
};

// Takes a renewalDate for a Roanoke continuous bond and determines if the
// date is before the last day to cancel (16 days prior to the `renewalDate`)
const getIsBeforeLastDayToCancel = (renewalDate: string) => {
  const today = moment();
  // This represents the last day users can request to terminate their bond. It
  // is set at 16 days prior to the `renewalDate` because it takes CBP up to 15
  // days to terminate and we need the termination date to be the day before
  // the renewalDate (at the latest)
  const lastDayToCancel = moment(renewalDate).subtract(15, 'days');
  return today < lastDayToCancel;
};

const fields = `
  ...usIorContinuousBondRequestFields
  deadline
  group {
    documents {
      id
    }
    memos {
      ...memoFields
    }
  }
  usIor {
    memos {
      ...memoFields
    }
  }
  tasks {
    ...workOrderTaskFields
    assignee {
      id
      name
    }
    steps {
      id
      name
      description
      isCompletedBySystem
      isFinalConfirmation
    }
    definition {
      id
      workOrderTaskType
    }
  }
`;

const validateRecord = (
  record: ApigUsIorContinuousBondRequest,
): { errors: string[] } => {
  const isInvalidToErrorMessage: [boolean, string][] = [
    [!record?.usIor?.iorNumber?.value, 'US IOR is missing IOR number'],
  ];

  const errors = isInvalidToErrorMessage
    .filter(([isInvalid, _]) => isInvalid)
    .map(([_, error]) => error);

  return { errors };
};

const usIorContinuousBondRequestRoute: WorkOrderRoute = {
  Page: UsIorContinuousBondRequest,
  recordType: RecordType.US_IOR_CONTINUOUS_BOND_REQUEST,
  fields,
  SecondaryHeaderContents: [UsIorContinuousBondRequestSecondaryHeader],
  fragments: `${usIorContinuousBondRequestFragments}${workOrderTaskFragments}${workOrderTaskStepFragments}${memoFragments}`,
  transformUpdateRecordInput: transformUsIorContinuousBondRequest.toSchema,
  kebabMenuItems: [
    appRecordAbiDataMenuItem,
    appRecordAssignWorkOrderTaskMenuItem,
    appRecordMemoMenuItem,
    usIorContinuousBondRequestChargeBondKebabMenuItem,
    usIorContinuousBondRequestChargeBondRenewalKebabMenuItem,
    appRecordSendAbiMessage,
  ],
  submitButtons: [
    completeWorkOrderTaskButton(),
    {
      key: 'acquireContinuousBond',
      text: 'Acquire Bond',
      show: ({ existingRecord, currentUser, editMode }) => {
        const isOperator = currentUser.accountType === AccountType.OPERATOR;
        const isCompletedOrCanceled = [
          WorkOrderStatus.COMPLETED,
          WorkOrderStatus.CANCELED,
        ].includes(existingRecord?.status);
        // Only show this button for operators viewing the WO in readOnly
        // mode when the WO is not yet COMPLETED or CANCELED
        return isOperator && !editMode && !isCompletedOrCanceled;
      },
      onConfirm: ({ isValid, existingRecord }) =>
        new Promise<AppRecordOnConfirmPayload>((resolve) => {
          const { errors } = validateRecord(existingRecord);
          if (!isValid) errors.push('Sorry, an error ocurred');
          if (errors.length) {
            errors.forEach((error) => message.error(error));
            return resolve({ canceled: true });
          } else {
            return resolve({ canceled: false });
          }
        }),
      onSubmit: async ({ updatedRecord }) =>
        // updatedRecord would be a usIorContinuousBondRequest
        await executeMutation({
          mutation: createWorkOrderMilestoneMutation,
          variables: {
            idempotencyKey: v4(),
            input: {
              allowDuplicate: true,
              name: WorkOrderMilestoneName.US_IOR_CONTINUOUS_BOND_REQUEST_ACQUISITION_REQUESTED,
              workOrder: {
                id: updatedRecord.id,
                version: updatedRecord.version,
              },
            },
          },
        }),
    },
    {
      key: 'cancelRenewal',
      text: 'Cancel Renewal',
      // We should not validate fields when canceling. The user can always
      // cancel regardless of the data (assuming the `show` condition is met)
      skipValidation: () => true,
      skipUpdateRecord: () => true,
      show: ({ existingRecord, currentUser, editMode, workOrderTaskType }) => {
        const isOperator = currentUser.accountType === AccountType.OPERATOR;
        const isCompleted =
          existingRecord?.status === WorkOrderStatus.COMPLETED;
        const renewalDate = getPresentRoanokeContinuousBondRenewalDateFromUsIor(
          existingRecord?.usIor,
        );
        // If there's not a renewalDate, this means the present bond for the
        // usIor was not a valid Roanoke continuous bond or the renewalDate was
        // not set on the present Roanoke continuous bond. Regardless of the
        // reason, we should not show this button as we need the renewalDate to
        // determine if canceling is allowed
        if (!renewalDate) return false;
        const isBeforeLastDayToCancel = getIsBeforeLastDayToCancel(renewalDate);
        // If `renewalCanceled` is set this means the operator has already
        // requested the renewal be canceled.
        const isRenewalCanceled = existingRecord?.renewalCanceled?.time;
        // Only show this button for operators viewing the WO in readOnly mode
        // when the WO is already COMPLETED, today is before the last day to
        // cancel, and the renewal has not already been canceled
        return (
          isOperator &&
          !editMode &&
          isCompleted &&
          !isRecordCanceled(existingRecord) &&
          existingRecord?.renewalScheduleArn &&
          isBeforeLastDayToCancel &&
          !isRenewalCanceled &&
          workOrderTaskType !==
            WorkOrderTaskType.US_IOR_CONTINUOUS_BOND_CHARGE_BOND_RENEWAL_EXCEPTION
        );
      },
      onConfirm: ({ dispatch, isValid }) =>
        new Promise<AppRecordOnConfirmPayload>((resolve) => {
          if (isValid) {
            dispatch(
              setModal({
                key: ModalKey.CANCEL_US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_CONFIRMATION,
                props: {
                  visible: true,
                  onOk: () => resolve({ canceled: false }),
                  onCancel: () => resolve({ canceled: true }),
                },
              }),
            );
          } else {
            return resolve({ canceled: true });
          }
        }),
      onSubmit: async ({ updatedRecord: { id, version } }) =>
        // updatedRecord would be a usIorContinuousBondRequest
        await executeMutation({
          mutation: cancelUsIorContinuousBondRequestRenewal,
          variables: { id, version },
        }),
    },
  ],
  getAdditionalInfoCards: ({ record }) => [
    <BondInfoCard record={record.usIor} />,
  ],
  getAdditionalWorkOrderInfo: ({ record }) => {
    const { renewalScheduleArn, usIor } =
      record as ApigUsIorContinuousBondRequest;
    const renewalDate =
      getPresentRoanokeContinuousBondRenewalDateFromUsIor(usIor);
    return [
      {
        label: 'Renewal Scheduled',
        value: renewalScheduleArn ? 'Yes' : 'No',
      },
      ...(isRenewalDateEnabled && renewalScheduleArn && renewalDate
        ? [
            {
              label: 'Renewal Schedule Date',
              value: renewalDate,
            },
          ]
        : []),
    ];
  },
};

export default usIorContinuousBondRequestRoute;

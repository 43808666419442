import { formatRecordName } from '@xbcb/js-utils';
import { AccountType, RecordType } from '@xbcb/shared-types';

export interface MemoConfiguration {
  // Account types that cannot view the memos
  blocklist?: AccountType[];
  // If label is not provided and labelNamepath is undefined then text is used as
  // the title for the AppRecordMemoModal.
  text: string;
  key: string;
  // Indicates which field in the record to use when creating the title in
  // AppRecordMemoModal (title = "Record[labelNamepath] Memos").
  // If not provided, then the modal will use either "label" or the text property
  // instead. This is a . separated path to the label used in lodash.get.
  labelNamePath?: string;
  label?: string;
  // Indicates which field in the record to use when locating the memos.
  // If not provided, the top level of the AppRecord will be used to find memos.
  // This is a . separated path to the record used in lodash.get.
  namePathToRecordWithMemos?: string;
}

const shipmentText = formatRecordName({
  recordType: RecordType.SHIPMENT,
  accountType: AccountType.OPERATOR,
});

const shipperText = formatRecordName({
  recordType: RecordType.SHIPPER,
  accountType: AccountType.OPERATOR,
});

const usIorText = formatRecordName({
  recordType: RecordType.US_IOR,
  accountType: AccountType.OPERATOR,
});

const deIorText = formatRecordName({
  recordType: RecordType.DE_IOR,
  accountType: AccountType.OPERATOR,
});

const gbIorText = formatRecordName({
  recordType: RecordType.GB_IOR,
  accountType: AccountType.OPERATOR,
});

const nlIorText = formatRecordName({
  recordType: RecordType.NL_IOR,
  accountType: AccountType.OPERATOR,
});

const frIorText = formatRecordName({
  recordType: RecordType.FR_IOR,
  accountType: AccountType.OPERATOR,
});

export const appRecordMemoConfiguration: {
  [key: string]: MemoConfiguration[];
} = {
  [RecordType.SHIPMENT]: [
    {
      text: shipmentText,
      key: 'groupMemos',
      namePathToRecordWithMemos: 'workOrderGroup',
    },
  ],
  [RecordType.SHIPPER]: [
    {
      text: shipperText,
      key: 'shipperMemos',
      labelNamePath: 'name',
    },
  ],
  [RecordType.US_CONSUMPTION_ENTRY]: [
    {
      text: shipmentText,
      key: 'groupMemos',
      namePathToRecordWithMemos: 'group',
    },
    {
      text: shipperText,
      key: 'shipperMemos',
      labelNamePath: 'group.shipper.name',
      namePathToRecordWithMemos: 'group.shipper',
    },
    {
      text: usIorText,
      key: 'usIorMemos',
      labelNamePath: 'ior.usIor.name',
      namePathToRecordWithMemos: 'ior.usIor',
    },
  ],
  [RecordType.US_TYPE86_ENTRY]: [
    {
      text: shipmentText,
      key: 'groupMemos',
      namePathToRecordWithMemos: 'group',
    },
    {
      text: shipperText,
      key: 'shipperMemos',
      labelNamePath: 'group.shipper.name',
      namePathToRecordWithMemos: 'group.shipper',
    },
    {
      text: usIorText,
      key: 'usIorMemos',
      labelNamePath: 'ior.usIor.name',
      namePathToRecordWithMemos: 'ior.usIor',
    },
  ],
  [RecordType.DE_CUSTOMS_ENTRY]: [
    {
      text: shipmentText,
      key: 'groupMemos',
      namePathToRecordWithMemos: 'group',
    },
    {
      text: shipperText,
      key: 'shipperMemos',
      labelNamePath: 'group.shipper.name',
      namePathToRecordWithMemos: 'group.shipper',
    },
    {
      text: deIorText,
      key: 'IorMemos',
      labelNamePath: 'ior.ior.name',
      namePathToRecordWithMemos: 'ior.Ior',
    },
  ],
  [RecordType.NL_CUSTOMS_ENTRY]: [
    {
      text: shipmentText,
      key: 'groupMemos',
      namePathToRecordWithMemos: 'group',
    },
    {
      text: shipperText,
      key: 'shipperMemos',
      labelNamePath: 'group.shipper.name',
      namePathToRecordWithMemos: 'group.shipper',
    },
    {
      text: nlIorText,
      key: 'IorMemos',
      labelNamePath: 'ior.ior.name',
      namePathToRecordWithMemos: 'ior.Ior',
    },
  ],
  [RecordType.FR_CUSTOMS_ENTRY]: [
    {
      text: shipmentText,
      key: 'groupMemos',
      namePathToRecordWithMemos: 'group',
    },
    {
      text: shipperText,
      key: 'shipperMemos',
      labelNamePath: 'group.shipper.name',
      namePathToRecordWithMemos: 'group.shipper',
    },
    {
      text: frIorText,
      key: 'IorMemos',
      labelNamePath: 'ior.ior.name',
      namePathToRecordWithMemos: 'ior.Ior',
    },
  ],
  [RecordType.GB_CUSTOMS_ENTRY]: [
    {
      text: shipmentText,
      key: 'groupMemos',
      namePathToRecordWithMemos: 'group',
    },
    {
      text: shipperText,
      key: 'shipperMemos',
      labelNamePath: 'group.shipper.name',
      namePathToRecordWithMemos: 'group.shipper',
    },
    {
      text: gbIorText,
      key: 'IorMemos',
      labelNamePath: 'ior.ior.name',
      namePathToRecordWithMemos: 'ior.Ior',
    },
  ],
  [RecordType.US_IOR_CONTINUOUS_BOND_REQUEST]: [
    {
      text: shipmentText,
      key: 'groupMemos',
      namePathToRecordWithMemos: 'group',
    },
    {
      text: usIorText,
      key: 'usIorMemos',
      labelNamePath: 'usIor.name',
      namePathToRecordWithMemos: 'usIor',
    },
  ],
  [RecordType.US_IOR]: [
    {
      text: usIorText,
      key: 'usIorMemos',
      labelNamePath: 'name',
    },
  ],
  [RecordType.DE_IOR]: [
    {
      text: deIorText,
      key: 'deIorMemos',
      labelNamePath: 'name',
    },
  ],
  [RecordType.GB_IOR]: [
    {
      text: gbIorText,
      key: 'gbIorMemos',
      labelNamePath: 'name',
    },
  ],
  [RecordType.NL_IOR]: [
    {
      text: nlIorText,
      key: 'nlIorMemos',
      labelNamePath: 'name',
    },
  ],
  [RecordType.FR_IOR]: [
    {
      text: frIorText,
      key: 'frIorMemos',
      labelNamePath: 'name',
    },
  ],
  [RecordType.US_ISF]: [
    {
      text: shipmentText,
      key: 'groupMemos',
      namePathToRecordWithMemos: 'group',
    },
    {
      text: usIorText,
      key: 'usIorMemos',
      labelNamePath: 'ior.usIor.name',
      namePathToRecordWithMemos: 'ior.usIor',
    },
  ],
  [RecordType.US_IN_BOND]: [
    {
      text: shipmentText,
      key: 'groupMemos',
      namePathToRecordWithMemos: 'group',
    },
    {
      text: usIorText,
      key: 'usIorMemos',
      labelNamePath: 'ior.usIor.name',
      namePathToRecordWithMemos: 'ior.usIor',
    },
  ],
};

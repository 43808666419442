import React from 'react';
import styled from 'styled-components';
import { AccountType } from '@xbcb/shared-types';
import { isShipmentWorkOrderType } from '@xbcb/core';
import usConsumptionEntryRoute from './usConsumptionEntry';
import usType86EntryRoute from './usType86Entry';
import deliveryOrderRoute from './deliveryOrder';
import usPostSummaryCorrectionRoute from './usPostSummaryCorrection';
import usIorContinuousBondRequestRoute from './usIorContinuousBondRequest';
import usIsfRoute from './usIsf';
import usInBondRoute from './usInBond';
import deCustomsEntryRoute from './deCustomsEntry';
import gbCustomsEntryRoute from './gbCustomsEntry';
import nlCustomsEntryRoute from './nlCustomsEntry';
import frCustomsEntryRoute from './frCustomsEntry';
import { AppRecordRoute } from '../../types';
import PONumbers from 'components/PONumbers';
import TagForwarderButton from 'components/TagForwarderButton';
import WorkOrder, { WorkOrderProps } from 'components/WorkOrder';
import { AppRecordProvidedProps } from 'routes';
import usIorActivationRoute from './usIorActivation';
import deIorActivationRoute from './deIorActivation';
import gbIorActivationRoute from './gbIorActivation';
import nlIorActivationRoute from './nlIorActivation';
import frIorActivationRoute from './frIorActivation';
import { appRecordWorkOrderStatus } from '../utils';
import { uncancelWorkOrderButton } from '../utils/uncancelWorkOrderButton';
import { cancelWorkOrderButton } from '../utils/cancelWorkOrderButton';
import { formatRecordName } from '@xbcb/js-utils';
import {
  startWorkOrderTaskButton,
  stopWorkOrderTaskButton,
} from '../utils/startStopWorkOrderTaskButton';
import NavigateToWorkOrder from 'components/NavigateToWorkOrder';
import { escalateWorkOrderTaskButton } from '../utils/escalateWorkOrderTaskButton';
import ProcessingErrorsAlert from 'components/ProcessingErrorsAlert';
import { isGlitchWatchUiAlertButtonEnabled } from '../../../libs/featureFlags';

const StyledDiv = styled.div`
  display: inline-flex;
  vertical-align: top;
`;

export type WorkOrderRoute = AppRecordRoute &
  Pick<
    WorkOrderProps,
    'getAdditionalInfoCards' | 'getAdditionalWorkOrderInfo' | 'disableCancel'
  >;

// AppRecord gives props, this threads them to WorkOrder which passes them down to its child component.
// The rendered "Page" will get all of it's props injected by WorkOrder (just the same as with any component that leverages AppRecord)
export const WorkOrderWrapper =
  ({
    Page,
    getAdditionalWorkOrderInfo,
    getAdditionalInfoCards,
  }: WorkOrderRoute): React.FC<AppRecordProvidedProps> =>
  (props) =>
    (
      <WorkOrder
        {...props}
        getAdditionalInfoCards={getAdditionalInfoCards}
        getAdditionalWorkOrderInfo={getAdditionalWorkOrderInfo}
      >
        <Page />
      </WorkOrder>
    );

const mapWorkOrderPages = (workOrderRoute: WorkOrderRoute) => {
  // a Page is provided inside of a Work Order route. Here we simply take that page and automatically wrap it in the Work Order higher-order component
  workOrderRoute.Page = WorkOrderWrapper(workOrderRoute) as React.FC;
  const { status, AdditionalTitleContent, recordType, disableCancel } =
    workOrderRoute;
  // The below is to ensure the correct fields are present, even if they are not present in the work order fragments.
  // GraphQL merges the fields for us so even if they are defined in the fragments, it does not cause an issue.
  // The only scenario where this could cause as issue is for a work order schema that specifically doesn't have these fields.
  // But this is unlikely to happen.
  // Note: This will only show if the fragments has a group.shipment.id field
  if (!status) {
    const appRecordWorkOrderStatusFields =
      'group { shipment { id version friendlyId clientIdentifier clientReferences { bookingId }  } }';
    workOrderRoute.fields =
      workOrderRoute.fields + appRecordWorkOrderStatusFields;
    workOrderRoute.status = appRecordWorkOrderStatus;
  }

  // add invoices fields to the query for all the workOrders
  // adding billToParty as the <invoiceCharges/> which is used for all components uses it for filtering on forwarder/shipper views
  const invoiceFields = `group
  {
    invoices {
      id
      status
      number
      billToParty {
        id
      }
      lines {
        type
        amount {
          value
          currency
        }
      }
    }
  }`;
  workOrderRoute.fields = workOrderRoute.fields + invoiceFields;
  const WrappedAdditionalTitleContent = (props: AppRecordProvidedProps) => (
    <StyledDiv>
      {/* Only shipment WO types have PO numbers */}
      {isShipmentWorkOrderType(props.record?.id) && (
        // For p0 we always want this field to be disabled for all WOs, they can edit it from the shipment page
        <PONumbers {...props} disabled />
      )}
      <TagForwarderButton workOrderGroup={props.record?.group} isWorkOrder />
      {isGlitchWatchUiAlertButtonEnabled && props.record?.id && (
        <ProcessingErrorsAlert recordId={props.record.id} />
      )}

      {AdditionalTitleContent && <AdditionalTitleContent {...props} />}
    </StyledDiv>
  );
  workOrderRoute.AdditionalTitleContent = WrappedAdditionalTitleContent;

  const poNumbersFields = `
    group {
      tags {
        key
        value
      }
    }
  `;
  const tagForwarderFields = `
    group {
      id
      version
      forwarders {
        forwarder {
          id
          name
          tags {
            key
            value
          }
          externalIntegrationReferences {
            externalIntegrationPartner
            externalIntegrationType
          }
        }
      }
    }
  `;
  const tasksFields = `
    tasks {
      id
      name
      status
      assignee {
        id
        name
      }
      definition {
        id
        workOrderTaskType
      }
      assignmentTeam {
        id
        backupTeam {
          id
        }
      }
    }`;
  workOrderRoute.fields =
    workOrderRoute.fields + tagForwarderFields + poNumbersFields + tasksFields;

  const displayName = formatRecordName({
    recordType,
    accountType: AccountType.OPERATOR,
  });

  // Add start/stop task buttons to WOs with task tab
  workOrderRoute.submitButtons?.unshift(
    startWorkOrderTaskButton(recordType),
    stopWorkOrderTaskButton(recordType),
    escalateWorkOrderTaskButton(recordType),
  );
  const allSubmitButtons = workOrderRoute.submitButtons?.slice(0) || [];
  // if we change the key of cancel work order button, we should also change it here
  if (
    allSubmitButtons.every(({ key }) => key !== `Cancel ${displayName}`) &&
    !disableCancel
  ) {
    allSubmitButtons.push(cancelWorkOrderButton(recordType));
  }
  // if we change the key of uncancel work order button, we should also change it here
  if (
    allSubmitButtons.every(({ key }) => key !== `Uncancel ${displayName}`) &&
    !disableCancel
  ) {
    allSubmitButtons.push(uncancelWorkOrderButton(recordType));
  }
  workOrderRoute.submitButtons = allSubmitButtons;
  workOrderRoute.hideDeleteKebabMenuItem = () => true; // The operator should use cancel work order button now

  // add commonSecondaryHeaders component to all the work order secondary headers
  const commonSecondaryHeaders = [NavigateToWorkOrder];

  if (!workOrderRoute.SecondaryHeaderContents) {
    // case 1: work order doesn't have SecondaryHeaderContents
    workOrderRoute.SecondaryHeaderContents = commonSecondaryHeaders;
  } else {
    // case 2: work order has SecondaryHeaderContents, add the non-new common headers at the end
    workOrderRoute.SecondaryHeaderContents.push(
      ...commonSecondaryHeaders.filter(
        (commonSecondaryHeader) =>
          !workOrderRoute.SecondaryHeaderContents?.includes(
            commonSecondaryHeader,
          ),
      ),
    );
  }

  return workOrderRoute;
};

const wrappedWorkOrderRoutes = [
  usConsumptionEntryRoute,
  usPostSummaryCorrectionRoute,
  usIorContinuousBondRequestRoute,
  usIsfRoute,
  usIorActivationRoute,
  deIorActivationRoute,
  gbIorActivationRoute,
  nlIorActivationRoute,
  frIorActivationRoute,
  usInBondRoute,
  deliveryOrderRoute,
  deCustomsEntryRoute,
  gbCustomsEntryRoute,
  nlCustomsEntryRoute,
  frCustomsEntryRoute,
  usType86EntryRoute,
].map(mapWorkOrderPages);

export default wrappedWorkOrderRoutes;

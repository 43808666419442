import { Button, Dropdown, MenuProps, Tooltip, message } from 'antd';
import { useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountType, KeywordSearchOperator } from '@xbcb/shared-types';
import { DataCyPrefix, ModalKey } from '@xbcb/ui-types';
import { WorkOrderTaskStatus } from '@xbcb/work-order-types';
import { isMonsEnv } from '@xbcb/ui-env';
import { LOGOUT_ASYNC, setActiveBroker } from 'actions';
import profile from 'assets/profile.svg';
import ChannelConnnectionStatusButton from 'components/ChannelConnectionStatusButton';
import EscalatedToMe from 'components/EscalatedToMe';
import GlobalSearchForm from 'components/GlobalSearchForm';
import HelpVideosButton from 'components/HelpVideosButton';
import Logo from 'components/Logo';
import NewBundleAvailable from 'components/NewBundleAvailable';
import RecentlyViewed from 'components/RecentlyViewed';
import StatusDisplay from 'components/StatusDisplay';
import SwitchUserButton from 'components/SwitchUserButton';
import TeamActionsButton from 'components/TeamActionsButton';
import { getAvatarUrl } from 'libs/getAvatarUrl';
import { getOperatingRegion } from 'libs/getOperatingRegion';
import { useCurrentUser, useCustomsBrokerId, useStatus } from 'libs/hooks';
import {
  FEATURE_RELEASE_KEY,
  restrictedDeployment,
} from 'libs/restrictedDeployment';
import { SEARCH_TASKS } from 'libs/sharedQueries';
import {
  isAssignmentTeamLead,
  isSubjectMatterExpert,
} from 'libs/teamStructureHelpers';
import { useTypedSelector } from 'reducers';
import {
  ConnectionStatusOperatingRegion,
  channelConnectionEnabledRegions,
} from 'types';
import {
  StyledHeader,
  StyledImage,
  StyledImageContainer,
  StyledPrivateActions,
  StyledSpan,
  StyledUserActions,
  StyledUserLabel,
} from './styles';
import { apolloLogout } from 'libs/logout';
import { useModal } from '@xbcb/ui-utils';
import GeneralConfirmationModal from 'components/GeneralConfirmationModal';
import ActiveBrokerSelect from 'components/ActiveBrokerSelect';
import { isMultiBrokerLoginEnabled } from 'featureFlags';

const isMons = isMonsEnv();

export type AppHeaderProps = {
  isLoading?: boolean;
};

const AppHeader = ({ isLoading }: AppHeaderProps): React.ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isVisible = usePageVisibility();
  const { setModal } = useModal(ModalKey.GENERAL_CONFIRMATION);
  const { currentStatus } = useStatus();
  const newBundleAvailable = useTypedSelector(
    ({ ui: { newContent } }) => newContent,
  );
  const [escalatedToMeTaskCount, setEscalatedToMeTaskCount] = useState(0);
  const currentUser = useCurrentUser();
  const customsBrokerId = useCustomsBrokerId();
  // Get avatarUrl and company logo url
  const avatarUrl = getAvatarUrl(currentUser.documents) || '';
  const logoUrl = getAvatarUrl(currentUser.account?.documents) || '';
  const operatingRegion = getOperatingRegion(customsBrokerId);
  const channelConnectionButtonEnabledForRegion =
    operatingRegion &&
    channelConnectionEnabledRegions.includes(operatingRegion);
  const onLoginPage = location.pathname === '/login';
  const onActionPage = location.pathname === '/action';
  const onboardingPage = ['/pending'].includes(location.pathname);
  const showSearchAndRecent = !(isLoading || onLoginPage || onActionPage);
  const dropdownItems: MenuProps['items'] = [
    { key: 'profile', label: 'Profile' },
    isMons ? null : { key: 'logout', label: 'Logout' },
  ];

  const logout = useCallback(async () => {
    dispatch(LOGOUT_ASYNC());
    dispatch(setActiveBroker(undefined));
    // Remove all cached data on logout to avoid cross-session contamination
    await apolloLogout();
    history.push('/login');
  }, [dispatch, history]);

  const handleOptionClick: MenuProps['onClick'] = ({ key }: any) => {
    if (key === 'profile') {
      history.push('/profile');
    } else if (key === 'logout') {
      setModal({
        title: 'Are you sure you want to log out?',
        onOk: () => {
          logout();
        },
        visible: true,
      });
    } else {
      return;
    }
  };

  if (!restrictedDeployment(FEATURE_RELEASE_KEY.PERSONNEL_DASHBOARD)) {
    dropdownItems.unshift({
      key: 'currentStatus',
      label: <StatusDisplay currentStatus={currentStatus} />,
    });
  }

  const shouldSkipPollingTasks =
    !isVisible ||
    (!isAssignmentTeamLead(currentUser) && !isSubjectMatterExpert(currentUser));

  // Poll API for tasks currently escalated to me every 5 minutes
  const { data } = useQuery(SEARCH_TASKS, {
    pollInterval: 300000,
    skip: shouldSkipPollingTasks,
    variables: {
      input: {
        searchCriteria: {
          currentlyEscalatedToId: {
            values: [currentUser.id],
            operator: KeywordSearchOperator.EQUALS,
          },
          status: {
            values: WorkOrderTaskStatus.ESCALATED,
            operator: KeywordSearchOperator.EQUALS,
          },
        },
      },
    },
  });
  const currentTaskCount = data?.searchWorkOrderTasks?.totalResults ?? 0;

  useEffect(() => {
    if (currentTaskCount > escalatedToMeTaskCount) {
      message.info(`You have unresolved work order task escalations`);
    }
    setEscalatedToMeTaskCount(currentTaskCount);
  }, [currentTaskCount, escalatedToMeTaskCount]);

  return (
    <StyledHeader className="app-header">
      <GeneralConfirmationModal />
      <Logo url={logoUrl} />
      <div className="main-header">
        {showSearchAndRecent && !onboardingPage ? (
          <>
            <div className="search-recently-viewed-actions">
              <GlobalSearchForm />
              <RecentlyViewed />
              {escalatedToMeTaskCount > 0 && (
                <EscalatedToMe taskCount={escalatedToMeTaskCount} />
              )}
            </div>
            <StyledPrivateActions>
              {currentUser.accountType === AccountType.OPERATOR &&
                isMultiBrokerLoginEnabled && <ActiveBrokerSelect />}
              {currentUser.accountType === AccountType.OPERATOR && (
                <TeamActionsButton />
              )}
              {
                // TODO: Remove PROD check once UAT complete.
                channelConnectionButtonEnabledForRegion && (
                  <ChannelConnnectionStatusButton
                    operatingRegion={
                      operatingRegion as ConnectionStatusOperatingRegion
                    }
                  />
                )
              }
              <HelpVideosButton />
              <Dropdown
                placement="bottomRight"
                menu={{ items: dropdownItems, onClick: handleOptionClick }}
              >
                <StyledUserActions>
                  {currentUser.originalUser && (
                    <Tooltip
                      placement="leftBottom"
                      title="Switch back to operator view."
                    >
                      <SwitchUserButton />
                    </Tooltip>
                  )}
                  {newBundleAvailable && <NewBundleAvailable />}
                  <StyledImageContainer
                    currentStatus={
                      restrictedDeployment(
                        FEATURE_RELEASE_KEY.PERSONNEL_DASHBOARD,
                      )
                        ? undefined
                        : currentStatus
                    }
                  >
                    <StyledImage
                      src={[avatarUrl, profile]}
                      alt={currentUser?.name}
                      data-cy={DataCyPrefix.APP_HEADER_AVATAR}
                    />
                  </StyledImageContainer>
                  <StyledUserLabel>
                    <h3>{currentUser?.account?.name}</h3>
                    <p>{currentUser?.name}</p>
                  </StyledUserLabel>
                </StyledUserActions>
              </Dropdown>
            </StyledPrivateActions>
          </>
        ) : (
          onboardingPage && (
            <div>
              <Button type="text" onClick={() => logout()}>
                <StyledSpan>Logout</StyledSpan>
              </Button>
            </div>
          )
        )}
      </div>
    </StyledHeader>
  );
};

export default AppHeader;

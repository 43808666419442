import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticationBoundary from 'components/AuthenticationBoundary';
import AuthenticatedRoutes from 'components/AuthenticatedRoutes';
import SignPage from 'components/SignPage';
import { Loading, NotFound } from '@xbcb/feedback-components';
import { isMonsEnv } from '@xbcb/ui-env';

const LinkAction = lazy(
  () => import(/* webpackChunkName: 'LinkAction' */ 'components/LinkAction'),
);
const Login = lazy(
  () => import(/* webpackChunkName: 'Login' */ 'components/Login'),
);
const Migrate = lazy(
  () => import(/* webpackChunkName: 'Migrate' */ 'components/MigrateUser'),
);

// react router v6 does not support the exact prop https://reactrouter.com/docs/en/v6/upgrading/v5
const Routes = (): React.ReactElement => (
  <Suspense fallback={<Loading isLoading />}>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/action" component={LinkAction} />
      {!isMonsEnv() ? <Route path="/migrate" component={Migrate} /> : null}
      {/* these routes are for unique use cases. They are non-standard routes. */}
      {/* used for loading state when downloading docs to avoid popup blocker */}
      <Route path="/loading">
        <Loading isLoading />
      </Route>
      {/* Visiting the SignPage does not require being logged in. Note we
    explicitly check whether we are on the sign page in some places via:
    `pathname.startsWith('/sign/')`, thus we need to reserve this name space
    (`/sign/`) for the sign page and/or update accordingly if we ever decide to
    change this in the future */}
      <Route path="/sign/:recordId" component={SignPage} />
      {/* this is the main content of the app */}
      <AuthenticationBoundary>
        <AuthenticatedRoutes />
      </AuthenticationBoundary>
      <Route component={NotFound} />
    </Switch>
  </Suspense>
);

export default Routes;

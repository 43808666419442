import { useQuery } from '@apollo/client';
import { RootState, useTypedSelector } from 'reducers';
import { useSelector } from 'react-redux';
import { AccountType, KeywordSearchOperator } from '@xbcb/shared-types';
import { Login, SearchCustomsBrokersQuery } from 'libs/sharedQueries';

import { isMonsEnv } from '@xbcb/ui-env';

const isMons = isMonsEnv();

const userAccountTypeMap: { [key: string]: AccountType } = {
  ShipperUser: AccountType.SHIPPER,
  ForwarderUser: AccountType.FORWARDER,
  OperatorUser: AccountType.OPERATOR,
};

// TODO use autogenerated return type from apollo client when ready
export const useCurrentUser = () => {
  const currentActiveBrokerId: string | undefined = useSelector(
    (state: RootState) => state.activeBroker.currentActiveBroker,
  );
  const { customType = '', userToken = '' } = useTypedSelector(
    ({ userLogin }: RootState) => ({
      userToken: userLogin.userToken,
      customType: userLogin['custom:type'],
    }),
  );

  const activated = !['onboarding', 'pending'].includes(customType);
  const variables = isMons ? {} : { cognitoSub: userToken };
  const skipQueries = isMons ? false : !userToken || activated === false;
  const { data, error, loading } = useQuery(Login, {
    variables,
    skip: skipQueries, // AppHeader tries to do a query when not logged in
  });
  const { data: queryResponse } = useQuery(SearchCustomsBrokersQuery, {
    variables: {
      input: {
        searchCriteria: {
          id: {
            operator: KeywordSearchOperator.ONE_OF,
            values: [currentActiveBrokerId],
          },
        },
      },
    },
    skip: skipQueries || !currentActiveBrokerId,
  });

  const user = data?.login?.user;
  if (user && !error) {
    const customsBroker = queryResponse?.searchCustomsBrokers?.results[0];
    const assumedUser = user.assumedUser;
    const currentUser = assumedUser ? assumedUser : user;
    const accountType = userAccountTypeMap[currentUser.__typename as any];
    const account = currentUser[accountType.toLowerCase()];
    return {
      ...currentUser,
      ...(assumedUser && { originalUser: user }),
      accountType,
      account,
      ...(customsBroker &&
        Object.keys(customsBroker).length > 0 && { customsBroker }),
    };
  }
  if (loading) return { loading: true };

  // TODO temporary - decide how to handle
  // eslint-disable-next-line no-console
  if (error) console.error(error);
  return {};
};
